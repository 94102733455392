const clickerLevels = [
    {score: 1, value: 0},
    {score: 2, value: 1000},
    {score: 3, value: 5000},
    {score: 4, value: 10000},
    {score: 5, value: 15000},
    {score: 6, value: 30000},
    {score: 7, value: 60000},
    {score: 8, value: 120000},
    {score: 9, value: 240000},
    {score: 10, value: 580000},
];

export default clickerLevels;