import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {initBackButton, initUtils, useInitData} from "@telegram-apps/sdk-react";
import {useTelegram} from "../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/modal/Modal";
import useAppContext from '../../state/useAppContext';
import Menu from "../../components/Menu";
const API_URL = process.env.REACT_APP_API_URL;

function Tasks() {
    const { user, actions, isLoading, error } = useAppContext();
    const initData = useInitData();
    const _id = user ? user._id : null;
    const userId = initData.user.id;
    const [tasks, setTasks] = useState([]);
    const utils = initUtils();
    const [backButton] = initBackButton();
    const navigate = useNavigate();
    const [activeTaskId, setActiveTaskId] = useState(null);

    console.log('user', user)
    const closeModal = () => {
        console.log('closeModal')
        setActiveTaskId(null);
    };

    backButton.on('click', () => {
        backButton.hide();
        navigate('/');
    });

    useEffect(() => {
        backButton.show();
    }, [backButton]);

    useEffect(() => {
        const fetchTasks = async () => {
            if (_id) {
                const result = await axios.get(`${API_URL}/tasks/${user._id}`);
                console.log(result)
                setTasks(result.data.tasks);
            }
        };
        fetchTasks();
    }, [_id]);


    const checkTaskCompletion = async (taskId) => {
        try {
            const response = await axios.get(`${API_URL}/tasks/${_id}/completed/${taskId}`);
            return response.data.isCompleted;
        } catch (error) {
            console.error('Error checking task completion:', error);
            return false;
        }
    };

    const startTask = async (task) => {
        try {
            const response = await axios.post(`${API_URL}/tasks/start`, {taskId: task._id, userId: _id});
            if (response.data.success) {
                // Update task state to reflect that it has started
                setTasks(tasks.map(t => t._id === task._id ? {...t, isStarted: true} : t));
            } else {
                console.error('Error starting task:', response.data.message);
            }
        } catch (error) {
            console.error('Error starting task:', error);
        }
    };

    const getTaskAction = (task) => {
        console.log('getTaskAction')

        if (!task.isStarted) {
            console.log('startTask')
            startTask(task);
        }

        if (task.type === 'subscribe_channel') {
            return () => utils.openTelegramLink(task.url);
        }
        if (task.type === 'welcome_bonus') {
            // return () => completeTask(task._id);
        }
        if (task.type === 'visit_link') {
            return () => utils.openLink(task.url);
        }
    }

    const completeTask = async (task) => {

        if (task.type === 'subscribe_channel') {
            const subscriptionCheck = await axios.post(`${API_URL}/tasks/check-subscription`, {userId: userId});

            if (!subscriptionCheck.data.isSubscribed) {
                alert('You are not subscribed to the channel!');
                return;
            }
        }
        // if (task.type === 'welcome_bonus') {
        // }
        // if (task.type === 'visit_link') {
        // }

        const response = await axios.post(`${API_URL}/tasks/complete`, {taskId: task._id, userId: _id});
        if (response.data.success) {
            setTasks(tasks.map(t => t._id === task._id ? {...t, isCompleted: true} : t));
        }
    };

    return (
        <div className="page">
        <div className="tasks-page">
            <h2 className="tasks-header">Onboarding tasks</h2>
            <ul className="task-list">
                {tasks.filter(task => {
                    if (task.type !== 'league' || task.isCompleted) {
                        return task;
                    }  else {
                        if (user.score > task.coins) {
                            return task;
                        }
                    }
                }).map(task => (
                    <li onClick={() => setActiveTaskId(activeTaskId !== task._id && !task.isCompleted && task._id)}
                        key={task._id}
                        className={`task-item ${task.isCompleted ? 'inactive' : ''}`}
                    >
                        <div className="task-icon">🎉</div>
                        {/* Use dynamic icons as needed */}
                        <div className="task-details">
                            <div className="task-title">{task.name}</div>
                            <div className="task-coins">🟡 {task.coins}</div>
                        </div>
                        <button className="task-action" onClick={() => setActiveTaskId(task._id)}>
                            >
                        </button>

                        {/* Conditional rendering of Modal */}
                        {activeTaskId === task._id && (
                            <Modal
                                isOpen
                                onClose={closeModal} // Close modal
                                title={task.name}
                            >
                                {task.description && <p>{task.description}</p>}
                                {task?.isStarted && (
                                    <button className="modal-action-button" onClick={() => completeTask(task)}>
                                        Check task
                                    </button>
                                )}
                                {!task?.isStarted && (
                                    <button className="modal-action-button" onClick={() => getTaskAction(task)}>
                                        Start with task
                                    </button>
                                )}
                            </Modal>
                        )}
                    </li>
                ))}
            </ul>
        </div>
        <div className="menu-container">
            <Menu/>
        </div>
        </div>
    )
        ;
}

export default Tasks;
