import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useAppContext from '../../state/useAppContext';

function UserStartStats() {
    const navigate = useNavigate();
    const { user, actions } = useAppContext();

    const handleButton = async () => {
        if (user.isPremium && user.score === 0) {
            actions.updateUser({id: user.id, score: 10000});
        }
        navigate('/congratulations');
    };


    if (!user) return <div>Loading...</div>;
    
    return (
        <div className="page">
            <div className="top-bar">
                <div className="top-bar-item active"></div>
                <div className="top-bar-item"></div>
            </div>
            <div className="welcome-text">
                <p>Hello, <strong>{user.username}</strong>!</p>
            </div>
            <div className="stats-text">
                {user.isPremium && user.score === 0 ? <p>You will get 10000 coins as a premium user!</p> : <p>Let's Go!</p>}
            </div>
            <button onClick={handleButton} className="gotcha-button">Gotcha</button>
        </div>
    );
}

export default UserStartStats;
