import React from 'react';
import {NavLink} from "react-router-dom";

function Menu() {
    const items = [
        {icon: '👫', title: 'Fren', path: '/friends'},
        {icon: '🚀', title: 'Boost', path: '/boosts'},
        {icon: '🎯', title: 'Tasks', path: '/tasks'},
    ];

    return (
        <div className="menu">
            {items.map((item) => <NavLink key={item.path} className="menu-item" to={item.path}>
                <div className="menu-item-icon-wrapper">
                    <span className="menu-item-icon">{item.icon}</span>
                </div>
                <div className="menu-item-label">{item.title}</div>
            </NavLink>)}
        </div>
    );
}

export default Menu;