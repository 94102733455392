import {useEffect, useRef} from "react";
import {getMaxEnergy} from "../utils/getMaxEnergy";
import energyRecoveryLevels from "../data/energyRecoveryLevels";

function Energy({energy, energyLevel, energyRecoveryLevel, setEnergy}) {
    const maxEnergy = getMaxEnergy(energyLevel);
    const progressWidth = (energy * 100) / maxEnergy;
    const energyRecovery = energyRecoveryLevels[(energyRecoveryLevel ?? 1) - 1]?.score ?? 1;

    console.log('Energy rendered!')

    // const timeoutRef = useRef(null);

    // useEffect(() => {
    //     const incrementEnergy = () => {
    //         clearTimeout(timeoutRef.current);
    //         timeoutRef.current = null;

    //         if (maxEnergy > energy) {
    //             const newEnergy = Math.min(energy + energyRecovery, maxEnergy);
    //             setEnergy(newEnergy);
    //         }
    //     };

    //     if (!timeoutRef.current) {
    //          timeoutRef.current = setTimeout(incrementEnergy, 1000);
    //     }
    // }, [energy, maxEnergy, energyRecovery, setEnergy]);

    return <div>
        <div className="energy-title">⚡️{energy}/{maxEnergy}</div>
        <div className="progress">
            <div style={{width: `${progressWidth}%`}} className="progress-bar"></div>
        </div>
    </div>
}

export default Energy