import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

if (process.env.REACT_APP_ENV === 'development') {
    require('./data/mockTelegram.js');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
