import {useNavigate} from 'react-router-dom';
import {useInitData} from '@telegram-apps/sdk-react';
import {useTelegram} from "../../hooks/useTelegram";
import {useEffect} from "react";
import useAppContext from '../../state/useAppContext';

function Welcome() {
    const navigate = useNavigate();
    const { actions } = useAppContext();
    const initData = useInitData();
    const tgUser = initData.user;
    const {tg} = useTelegram();

    useEffect(() => {
        tg.ready();
        tg.expand();
    }, [tg]);

    const handleJoin = async () => {
        actions.createUser({...tgUser, referrerId: 0});
        navigate('/user-start-stats');
    };

    return (
        <div className="page">
            <div className="intro-container">
                <div className="logo">Logo</div>
                <div className="intro-text">
                    <span role="img" aria-label="wave">👋</span> Hello {tgUser ? tgUser.username : 'Can\'t Find!'}
                    <p>This is simple text to introduce our app</p>
                </div>
                <button className="join-button" onClick={handleJoin}>Join</button>
            </div>
        </div>
    );
}

export default Welcome;