import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from 'axios';
import energyLevels from "../../data/energyLevels";

const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
    id: 0,
    _id: 0,
    username: '',
    firstName: '',
    lastName: '',
    score: 0,
    energy: 0,
    clickLevel: 0,
    energyLevel: 0,
    closeAppTime: 0,
    languageCode: '',
    allowsWriteToPm: false,
    isLoading: true,
}

export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (id, thunkAPI) => {
        try {
            const res = await axios.get(`${API_URL}/users/get-user/${id}`);
            return res.data;
        } catch (error) {
            console.log(error)
        }
    }
);

export const createUser = createAsyncThunk(
    'user/createUser',
    async (userDara, thunkAPI) => {
        console.log('userData', userDara)
        try {
            const res = await axios.post(`${API_URL}/users/create-user`, userDara);
            return res.data;
        } catch (error) {
            console.log(error)
        }
    }
);

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (userDara, thunkAPI) => {
        console.log('userData', userDara)
        try {
            const res = await axios.post(`${API_URL}/users/update-user`, userDara);
            return res.data;
        } catch (error) {
            console.log(error)
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        increaseScore: (state, action) => {
            state.score = state.score + action.payload;
        },
        increaseUserEnergy: (state, action) => {
            state.energy = state.energy + action.payload;
        },
        decreaseUserEnergy: (state, action) => {
            state.energy = state.energy - action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state) => {
            console.log('pending')
            state.isLoading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            console.log('action.payload', action.payload)
            if (action.payload?.id) {
                let energy = action.payload.energy;
                const closeAppTime = action.payload.closeAppTime;
                let accumulatedEnergy = 0;
                const maxEnergy = energyLevels[action.payload.energyLevel - 1].score;
                console.log('closeAppTime', closeAppTime)
                console.log('Date.now()', Date.now())
                console.log('Date.now() - closeAppTime', Date.now() - closeAppTime)
                if (closeAppTime) {
                    accumulatedEnergy = Math.floor((Date.now() - closeAppTime) / 1000);
                    console.log('accumulatedEnergy', accumulatedEnergy)
                }

                if ((energy + accumulatedEnergy) > maxEnergy) {
                    energy = maxEnergy;
                } else {
                    energy += accumulatedEnergy;
                }
                console.log('energy', energy)
                console.log('action.payload', action.payload)
                console.log('state', action.payload)
                console.log('{...state, energy: energy, isLoading: false}', {...action.payload, energy: energy, isLoading: false})


                return {...action.payload, energy: energy, isLoading: false};
            }

           state.isLoading = false;
        });
        builder.addCase(createUser.rejected, (state) => {
            console.log('rejected')
            state.isLoading = false;
        });
        builder.addCase(createUser.pending, (state) => {
            console.log('pending')
        });
        builder.addCase(createUser.fulfilled, (state, action) => {
            console.log('createUser.fulfilled', action.payload)
            return action.payload;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            console.log('rejected', state)
            console.log('rejected')
        });
        builder.addCase(updateUser.pending, (state) => {
            console.log('pending')
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            console.log('createUser.fulfilled', action.payload)
            return action.payload;
        });
        builder.addCase(updateUser.rejected, (state) => {
            console.log('rejected', state)
            console.log('rejected')
        });
    }
})

export const {increaseScore, increaseUserEnergy, decreaseUserEnergy} = userSlice.actions;

export const selectorUserData = (state) => state.user
export const selectorUserScore = (state) => state.user.score

export default userSlice.reducer