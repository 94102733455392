const flipLevels = [
    {score: 10, value: 10000},
    {score: 20, value: 20000},
    {score: 30, value: 30000},
    {score: 40, value: 40000},
    {score: 50, value: 50000},
    {score: 60, value: 60000},
    {score: 70, value: 70000},
    {score: 80, value: 80000},
    {score: 90, value: 90000},
    {score: 100, value: 100000},
];

export default flipLevels;
