import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchBoosts = async () => {
    try {
        const response = await axios.get(`${API_URL}/boosts`);
        return response.data;
    } catch (error) {
        console.error('Error fetching boosts:', error);
        throw error;
    }
};