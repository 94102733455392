import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useAppContext from '../../state/useAppContext';


function Congratulations() {
    const navigate = useNavigate();
    const { user } = useAppContext();

    if (!user) {
        return <div>Loading....</div>;
    }

    const handleButton = () => {
        navigate('/');
    }

    return (
        <div className="page">
            <div className="congrats-container">
                <div className="top-bar">
                    <div className="top-bar-item"></div>
                    <div className="top-bar-item active"></div>
                </div>
                <div className="congrats-text">
                    <span role="img" aria-label="trophy">🏆</span>
                    <p>Congratulation!</p>
                </div>
                <div className="coins-text">
                    <p>{user.score} coins</p>
                </div>
                <button onClick={handleButton} className="let-me-in-button">Let me in</button>
            </div>
        </div>
    );
}

export default Congratulations;
