import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {SDKProvider} from '@telegram-apps/sdk-react';
import {routes} from "./navigation/routes";
import Menu from "./components/Menu";
import React from "react";
import {TimerProvider} from './context/TimerContext';
import { AppProvider } from './state/AppProvider';

function App() {
    return (
            <SDKProvider acceptCustomStyles debug={process.env.REACT_APP_ENV === 'development'}>
                <BrowserRouter>
                <AppProvider>
                <TimerProvider>
                        <div className="App">
                            <div id="wrap">
                                <div id="content">
                                    <div className="container">
                                        <Routes>
                                            {routes.map((route) => <Route key={route.path} {...route} />)}
                                            <Route path='*' element={<Navigate to='/'/>}/>
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </div>
                </TimerProvider>
                </AppProvider>
                </BrowserRouter>
            </SDKProvider>
    );
}

export default App;
