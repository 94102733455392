import React, {useEffect, useState} from 'react';

const Boost = ({title, clickerLevel, isMaxLevel, nextLevelValue, incrementLevel, score}) => {
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        setActive(isMaxLevel || score < nextLevelValue)
    }, [isMaxLevel, score, nextLevelValue])

    return (
        <div className="upgrades-container">
            <div className={`task-item upgrade ${isActive ? 'inactive' : ''}`}
                onClick={isMaxLevel || score < nextLevelValue ? () => {} : incrementLevel}

            >
                <div>
                    <span>{title}</span>
                    🟡 {nextLevelValue} - Level: {clickerLevel}
                </div>
            </div>
        </div>
    );
}

export default Boost;