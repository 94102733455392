import React, { createContext, useState, useEffect } from 'react';

export const TimerContext = createContext({});

export const TimerProvider = ({ children }) => {
    const [isRunning, setIsRunning] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    const [showFlipCoin, setShowFlipCoin] = useState(false);
    const [timeLeftFlipCoin, setTimeLeftFlipCoin] = useState(0);

    const [isTurboRunning, setTurboRunning] = useState(false);
    const [timeLeftTurbo, setTimeLeftTurbo] = useState(0);

    const startFlipCoin = (duration) => {
        setShowFlipCoin(true);
        setTimeLeftFlipCoin(duration);
    };

    const startTurbo = (duration) => {
        setTurboRunning(true);
        setTimeLeftTurbo(duration);
    }

    const startTimer = (duration) => {
        setIsRunning(true);
        setTimeLeft(duration);
    };

    const stopTimer = () => {
        setIsRunning(false);
        setTimeLeft(0);
    };

    useEffect(() => {
        if (showFlipCoin && timeLeftFlipCoin > 0) {
            const interval = setInterval(() => {
                setTimeLeftFlipCoin((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (timeLeftFlipCoin === 0 && showFlipCoin) {
            setShowFlipCoin(false);
        }
    }, [showFlipCoin, timeLeftFlipCoin]);

    useEffect(() => {
        if (isTurboRunning && timeLeftTurbo > 0) {
            const interval = setInterval(() => {
                setTimeLeftTurbo((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else if (timeLeftTurbo === 0 && isTurboRunning) {
            setTurboRunning(false);
        }
    }, [isTurboRunning, timeLeftTurbo]);

    useEffect(() => {
        let timerInterval;
        if (isRunning && timeLeft > 0) {
            timerInterval = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0 && isRunning) {
            setIsRunning(false);
        }

        return () => clearInterval(timerInterval);
    }, [isRunning, timeLeft]);

    return (
        <TimerContext.Provider value={{
            isRunning,
            timeLeft,
            startTimer,
            stopTimer,
            showFlipCoin,
            setTimeLeftFlipCoin,
            startFlipCoin,
            startTurbo,
            isTurboRunning,
            setTurboRunning,
            }}>
            {children}
        </TimerContext.Provider>
    );
};
