const energyLevels = [
    {score: 1000, value: 0},
    {score: 2000, value: 1000},
    {score: 3000, value: 5000},
    {score: 4000, value: 10000},
    {score: 5000, value: 15000},
    {score: 6000, value: 30000},
    {score: 7000, value: 60000},
    {score: 8000, value: 120000},
    {score: 9000, value: 240000},
    {score: 10000, value: 580000},
];

export default energyLevels;