import {useSelector} from "react-redux";
import {useEffect} from "react";
import goldCoin from "../assets/gold-coin.svg";

function Score({score}) {
    return <div className="score">
        <div className="score-wrapper" >
            <img src={goldCoin} alt="gold-coin"/>
            <span>{score}</span>
        </div>

    </div>
}

export default Score