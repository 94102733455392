import React from "react";

function Popups({popups}) {
    return <>
        {popups.map((popup, index) => (
            <div
                key={index}
                className="points-popup"
                style={{
                    position: 'absolute',
                    top: `${popup.position.y}px`,
                    left: `${popup.position.x}px`,
                    pointerEvents: 'none',
                }}
            >
                +{popup.points}
            </div>
        ))}
    </>
}

export default Popups