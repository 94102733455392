import React, { useState } from "react";
import coinIcon from "../assets/coin.svg";
import "./FlipCoin.css";
import flipLevels from "../data/flipLevels"; // Add this import

export default function FlipCoin({ onClick, top, left, flipLevel }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showMissed, setShowMissed] = useState(false);

  const handleClick = () => {
    if (!isFlipped) {
      const currentFlipLevel = flipLevels[flipLevel - 1] || flipLevels[0];
      const successPercentage = currentFlipLevel.score; // Remove division by 100
      const success = Math.random() * 100 < successPercentage; // Multiply random by 100
      setIsSuccess(success);
     
      if (!success) {
        setShowMissed(true);
        setTimeout(() => setShowMissed(false), 100); // Hide "Missed" after 1 second
      }

      if (success) {
        setIsFlipped(true);
        onClick(success);
      }
    }
  };

  return (
    <div
      className={`flip-coin flip-coin-container ${isFlipped ? 'flipped' : ''}`}
      style={{ top: `${top}%`, left: `${left}%` }}
      onClick={handleClick}
    >
      <div className="flip-coin-inner">
        <div className="flip-coin-front">
        {showMissed && <div className="missed-popup">Missed</div>}
          <img src={coinIcon} alt="Flip Coin" />
        </div>
        <div className="flip-coin-back">
          <span className={isSuccess ? 'hidden' : ''}>x10</span>
        </div>
      </div>
    </div>
  );
}
